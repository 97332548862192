const VentionPartOptions = {
  category: {
    ST: {
      title: 'Structural Parts',
      description:
        'This library of parts includes aluminum extrusions, general aluminum plates, high-precision aluminum plates & joints, aluminum base plates and tooling plates, aluminum mounting plates, aluminum blocks, panels and more.',
    },
    MO: {
      title: 'Motion Parts',
      description:
        'This library of parts includes timing belt pulleys, grooved pulleys, belt, bearings, ball screws, shaft couplings, shaft collars, conveyor rollers, hinges, linear motion and more.',
    },
    HW: {
      title: 'Hardware Parts',
      description:
        'This library of parts includes rubber bumper, clamps, plastic caps, industrial fasteners, leveling feet & mounts, tools, rest buttons, rest pads, handles, tow bars and more.',
    },
    CE: {
      title: 'Control Parts',
      description: 'This library of parts includes motor controls, sensors, cables and more.',
    },
    RA: {
      title: 'Robot End-of-Arm Tools',
      description: 'This lirary of parts includes all robot end-of-arm tooling.',
    },
  },
  sub_category: {
    ST: {
      EXT: {
        title: 'High-Precision Aluminum Extrusions',
        description:
          'Vention’s high-precision extrusions rank best-in-class in term of structural rigidity, straightness, and precision. Vention’s unique “V-shape” interface has been specially designed to work with Vention’s high-precision assembly plates, enabling rigid and high-precision designs that cannot typically be done with aluminum extrusions. “V-shape” interfaces can also be used as a rail when combined with Vention’s linear motion components. All of Vention extrusions come in Vention’s distinctive blue color, suitable for 3D scanner and photometry applications. Extrusions are precision cut in increment of 45mm. They are ready to be assembled with no-subsequent manufacturing required.',
      },
      GP: {
        title: 'General Assembly Plates',
        description:
          'Vention’s general assembly plates are designed to be combined with Vention’s extrusions to maximize ease of assembly and lower costs. These plates are available in a variety of configurations, supporting all sorts of structural designs.',
      },
      HP: {
        title: 'High-Precision Assembly Plates',
        description:
          'Vention’s high-precision assembly plates and joints are designed to be combined with Vention’s extrusions, creating high-performance designs. Plates have been engineered with self-positioning “V-shape” locators, enabling precision assembly that cannot typically be done with conventional extrusion system.',
      },
      PN: {
        title: 'Panels & Table Tops',
        description: 'All Vention panels & Table Tops.',
      },
      RB: {
        title: 'Robot Mounting Plates',
        description: 'All Vention robot mounting plates.',
      },
      SP: {
        title: 'Specialty Assembly Plates',
        description: 'All Vention specialty plates & blocks.',
      },
      SE: {
        title: 'Safety Enclosures',
        description: 'All Vention machine safety elements.',
      },
    },
    MO: {
      BR: { title: 'Bearings', description: 'All Vention bearings.' },
      BS: { title: 'Ball Screws', description: 'All Vention ball screws.' },
      CP: {
        title: 'Shaft Couplings',
        description: 'All Vention shaft couplings.',
      },
      CV: {
        title: 'Conveyor Rollers',
        description: 'All Vention conveyor rollers.',
      },
      DC: { title: 'Drag Chain', description: 'All Vention drag chains.' },
      HG: { title: 'Hinges', description: 'All Vention hinges.' },
      LM: {
        title: 'Linear Motion',
        description:
          'All Vention linear motion including gantry plates, stepper motor mounting plates, ball screw mounting plates etc.',
      },
      PL: {
        title: 'Belts & Pulleys',
        description: 'All Vention belts and pulleys.',
      },
      RM: {
        title: 'Rotary Motion',
        description: 'All Vention rotary motion.',
      },
      SA: { title: 'Actuators', description: 'All Vention actuators.' },
      SM: {
        title: 'Stepper Motors',
        description: 'All Vention stepper motors.',
      },
      WL: {
        title: 'Caster Wheels',
        description: 'All Vention caster wheels including both medium and heavy duty.',
      },
    },
    HW: {
      // Note: It's on purpose that we don't display mundane HW-WS-001-* washers.
      BP: {
        title: 'Rubber Bumpers',
        description: 'All Vention rubber bumpers.',
      },
      CP: { title: 'Clamps', description: 'All Vention clamps.' },
      EC: { title: 'Plastic Caps', description: 'All Vention plastic caps.' },
      FN: {
        title: 'Industrial Fasteners',
        description: 'All Vention industrial fasteners.',
      },
      HD: { title: 'Handles', description: 'All Vention handles.' },
      LF: {
        title: 'Leveling Feet & Mounts',
        description: 'All Vention leveling feet & mounts.',
      },
      LT: { title: 'Latches', description: 'All Vention latches.' },
      RP: {
        title: 'Rest Buttons & Rest Pads',
        description: 'All Vention rest buttons and rest pads.',
      },
      SC: {
        title: 'Shaft Collars',
        description: 'All Vention shaft collars.',
      },
      TB: { title: 'Tow Bars', description: 'All Vention tow bars.' },
      TL: { title: 'Tools', description: 'All Vention tools.' },
      TW: { title: 'Tie Wraps', description: 'All Vention tie wraps.' },
      OG: {
        title: 'Organizers',
        description: 'All Vention organizing parts.',
      },
    },
    CE: {
      CL: {
        title: 'Controllers',
        description: 'All Vention motor controls.',
      },
      HW: {
        title: 'Hardware',
        description: 'All Vention control-related hardware.',
      },
      SN: { title: 'Sensors', description: 'All Vention sensors.' },
      SW: { title: 'Software', description: 'All Vention software.' },
      CA: {
        title: 'Electric Cables',
        description: 'All Vention electric cables.',
      },
    },
    RA: {
      EE: {
        title: 'Tool Changers',
        descripition: 'Tool changers allow for quick and easy changing of robot end-of-arm tools.',
      },
    },
  },
  availability: {
    not_released: 'Not released',
    internal: 'Not for release',
    soon: 'Coming soon',
    in_stock: 'Ships next day',
    deplete: 'In stock',
    back_order: 'Limited quantity available',
    deprecated: 'Obsolete',
    not_available: 'Not all parts available',
    virtual: 'For trial',
    ready: 'All parts available',
  },
  units: { metric: 'Metric', imperial: 'Imperial' },
  sub_category_sort: {
    /* Note: see PartsController.get_layout */
  },
}
export default VentionPartOptions
