import React from 'react'
import classNames from 'classnames'

interface Props {
  hintText: React.ReactNode
  size: 'small' | 'regular' | 'large'
}

class HintPopover extends React.Component<Props> {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    $('.hint-popover').popover({
      trigger: 'hover',
      html: true,
      placement: 'top',
      container: 'body',
    })
  }

  render() {
    const size = this.props.size || 'regular'
    return (
      <sup className='hint-popover' data-toggle='popover' data-content={this.props.hintText}>
        <i
          className={classNames('far fa-info-circle', {
            'fa-xs': size === 'small', 'fa-lg': size === 'large'
          })}
        ></i>
      </sup>
    )
  }
}
export default HintPopover
